<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="wasteTypes"
      :loading="tableLoading"
      :options.sync="options"
      :server-items-length="totalCount"
      @dblclick:row="onClickedRow"
    >
      <template v-slot:top>
        <v-toolbar flat color="transparent">
          <v-spacer/>
          <v-btn
            outlined small icon dark
            color="primary" class="mb-2 mr-4"
            @click="fetchData()"
          >
            <v-icon small>mdi-refresh</v-icon>
          </v-btn>
          <v-btn
            dark color="accent" class="mb-2"
            @click="$refs.editForm.open()"
          >
            <v-icon left>mdi-plus</v-icon>
            {{ $vuetify.breakpoint.smAndDown ? 'Ajouter' : 'Ajouter un type de déchet' }}
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.theoreticalWastesCount`]="{ item }">
        {{ item.theoreticalWastes.length }}
      </template>
      <template v-slot:[`item.question`]="{ item }">
        {{ item.question | truncate }}
      </template>
      <template v-slot:[`item.maximumContainerMass`]="{ item }">
        {{ item.maximumContainerMass }} kg
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ item.createdAtObject | formatDate('date') }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon small color="secondary"
            >
              <v-icon
                small
                @click="openItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-eye
              </v-icon>
            </v-btn>
          </template>
          <span>Voir</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon small color="info"
            >
              <v-icon
                small
                @click="$refs.editForm.open(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>Modifier</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon small color="error"
            >
              <v-icon
                small
                @click="$refs.removeForm.open(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <span>Supprimer</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <EditWasteType
      ref="editForm"
      @finished="fetchData"
    />
    <RemoveItem
      resource="wasteTypes"
      title="ce type de déchet"
      ref="removeForm"
      @finished="fetchData"
    />
  </div>
</template>

<script>
import EditWasteType from "./EditWasteType.vue"
import RemoveItem from "../RemoveItem.vue"

export default {
  components: { EditWasteType, RemoveItem },
  props: {},
  data() {
    return {
      tableLoading: false,
      wasteTypes: [],
      options: {},
      totalCount: 0,
      headers: [
        ...(this.$store.state.debugMode ? [{
          text: 'Id',
          value: 'id'
        }] : []),
        {
          text: 'Type de déchets',
          value: 'name'
        },
        {
          text: 'Question',
          value: 'question'
        },
        {
          text: "Masse maximale d'une benne",
          value: 'maximumContainerMass'
        },
        {
          text: 'Nb de déchets',
          value: 'theoreticalWastesCount'
        },
        {
          text: 'Créé le',
          value: 'createdAt'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchData()
      },
      deep: true,
    }
  },
  methods: {
    fetchData() {
      this.tableLoading = true
      this.$store.dispatch(
        'wasteTypes/fetchAll',
        this.options
      ).then(res => {
        this.tableLoading = false
        this.wasteTypes = res.member
        this.totalCount = res.totalItems
      })
    },
    openItem(item) {
      if (!item.id) {
        throw 'Cannot open wasteType, no id found'
      }
      this.$router.push({ name: 'ViewWasteType', params: { id: item.id } })
    },
    onClickedRow(e, { item }) {
      this.openItem(item)
    }
  }
}
</script>
