<template>
  <v-container>
    <v-card>
      <ListWasteTypes />
    </v-card>
  </v-container>
</template>

<script>
import ListWasteTypes from '../../components/WasteTypes/ListWasteTypes.vue'

export default {
  components: { ListWasteTypes }
}
</script>
